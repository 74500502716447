import Vue from "vue";
import VueRouter from "vue-router";

const Home = () =>
    import("../views/Home/Home.vue");
const Index = () =>
    import("../views/Home/index.vue");
const pay_QR = () =>
    import("../views/pay/pay_qr.vue");
const pay_trans = () =>
    import("../views/pay/pay_trans.vue");
const PaymentCreate = () =>
    import("../views/payment/payment_create.vue");


Vue.use(VueRouter);

const routes = [{
    path: "/",
    redirect: "/Index",
},
    {
        path: "/Home",
        component: Home,
    },
    {
        path: "/payment/create",
        component: PaymentCreate,
    },
    {
        path: "/Index",
        component: Index,
    },
    {
        path: "/pay/pay_QR",
        component: pay_QR,
    },
    {
        path: "/pay/pay_trans",
        component: pay_trans,
    }

];

const router = new VueRouter({
    routes,
});

router.afterEach(() => {
    window.scrollTo(0, 0);
});

export default router;