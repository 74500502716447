export default {
    account: {
        login: {
            login: "login",
            selectLanguage: "Select language",
            placeEmail: "Email",
            placePsd: "Password",
            noAccount: "No account?go",
            register: "register",
            btnLogin: "Login",
        },
        register: {
            register: "register",
            selectLanguage: "Select language",
            placeEmail: "Email",
            placePsd: "Password",
            placeCrmPsd: "Confirm Password",
            placeInviteId: "Invitation Code",
            haveAccount: "Already have an account?go",
            login: "login",
            btnRegister: "Register",
        },
    },
    Blind_box: {
        Blind_box_record: {
            title: "Lottery record",
        },
        Blind_box_voucher: {
            title: "Voucher",
            voucher: "Voucher",
            currentVoucher: "Current voucher",
        },
        Blind_box: {
            title: "Blind Box",
            goodluck: "GOOD LUCK",
            panelTit: "Please choose a blind box below",
            tip1: "You must win 2500 coupon for 1 draw",
            tip2: "You still have 2500 times to get it",
            limit1: "Draws remaining today",
            limit2: "10 Times",
            times: "Times",
            draw: "Draw",
            discount: "Discount",
            payable: "Payable",
            blind_box: "Blind box",
            available: "Available",
            lotteryRecord: "Lottery record",
            Determine_blind_box: "Determine blind box",
        },
    },
    Financial: {
        index: {
            finance: "finance",
            ieo: "IEO",
            LockMining: "Lock in mining",
            day: "day",
            unit: "rise",
            Daily_yield: "Daily yield",
        },
    },
    Follow: {
        index: {
            title: "Trader List",
            tip: "Data is updated every hour",
        },
    },
    Fund: {
        Assets_all_record: {
            title: "Financial records",
            number: "number",
            time: "time",
        },
        Assets_record: {
            title: "Account details",
            total_assets: "Total assets converted",
            Available_Credit: "Available Credit",
            locking: "locking",
            Financial_records: "Financial records",
            number: "number",
            time: "time",
        },
        Assets: {
            Account_record: "Account change record",
            Recharge: "Recharge",
            Collection: "Collection",
            Withdrawal: "Withdrawal",
            transfer: "transfer",
            Currency_transfer: "Currency transfer",
            Coins: "Coins",
            contract: "contract",
            Legal_currency: "Legal currency",
            option: "option",
            Equivalent: "Equivalent",
        },
        FundTransfer: {
            title: "Capital transfer",
            Select_Currency: "Select Currency",
            from: "from",
            Transfer_quantity: "Transfer quantity",
            to: "to",
        },
        Receive_withdraw_record: {
            title: "record",
        },
        Receive: {
            title: "Recharge",
            Digital_currency: "Digital currency",
            tip: "Contact customer service to ask for the recharge address",
            next: "next step",
            Recharge_address: "Recharge address",
            Recharge_number: "Recharge quantity",
            place_Recharge_number: "Please enter the recharge quantity",
            Payment_voucher: "Payment voucher",
            place_Payment_voucher: "Please enter the payment voucher",
        },
        Transfer: {
            Currency_transfer: "Currency transfer",
            Capital_transfer: "Capital transfer",
            from: "from",
            Transfer_number: "Transfer quantity",
            need_service_charge: "Additional service charge",
            to: "to",
            will_get: "You will get",
            Available_Credit: "Available Credit",
            Exchange_rate: "Exchange rate",
            Service_Charge: "Service Charge",
        },
        Withdraw: {
            title: "transfer accounts",
            Withdrawal: "Withdrawal",
            bank_card: "bank card",
            wallet_address: "Mention wallet address",
            Withdrawal_number: "Withdrawal quantity",
            available: "available",
            place_Withdrawal_number: "Please enter the withdrawal quantity",
            all: "All",
            Minimum_Withdrawal_number: "Minimum withdrawal quantity",
            password: "Account password",
            Service_Charge: "Service Charge",
        },
    },
    Guess: {
        title: "Guess",
        min: "min",
        Bullish: "Bullish",
        Bearish: "Bearish",
        Guess_amount: "Guess amount",
        place_Guess_amount: "Please enter the amount",
        Current_selection: "Current selection",
        Payable: "Payable",
        Guess_now: "Guess now",
        Available: "Available",
        Lottery_record: "Lottery record",
        Betting_record: "Betting record",
        Start_price: "Start price",
        End_price: "End price",
        Game_introduction: "Game introduction",
    },
    home: {
        title: "Bibox",
        Total_assets: "Total assets converted",
        Recharge: "Recharge",
        documentary: "documentary",
        transaction: "transaction",
        IEO: "IEO",
        Lock_mining: "Lock in mining",
        Investment_Financing: "Investment Financing",
        Currency_transfer: "Currency transfer",
        Withdrawal: "Withdrawal",
        Blind_box: "Blind box",
        Guess: "Guess",
        Daily_tasks: "Daily tasks",
        Sign_task: "Sign task",
        Buy_coins_quickly: "Buy coins quickly",
        Buy_quickly: "Buy quickly",
        day: "day",
        rise: "rise",
        Daily_yield: "Daily yield",
        quotation: "quotation",
        Transaction_pair: "Transaction pair",
        Latest_price: "Latest price",
        Rise_fall: "Rise all",
    },
    ieo: {
        ieo: "IEO subscription",
        Lock_up_cycle: "Lock up cycle",
        day: "day",
        Closed: "Closed",
        Completed: "Completed",
        Subscribed: "Subscribed",
        surplus: "surplus",
        My_subscription: "My subscription",
        Subscription_time: "Subscription time",
        currency: "currency",
        Requested_number: "Requested quantity",
        Pass_number: "Number of Passes",
        Market_time: "Market time",
    },
    Invest: {
        conduct: "conduct financial transactions",
        Special_area: "Special area",
        Annualized_rate: "Annualized rate",
        Linked_price: "Linked price",
        Remaining_share: "Remaining share",
        Position_duration: "Position duration",
        due_date: "due date",
        My_conduct: "My financial management",
        all: "all",
        fail: "fail",
        Purchased: "Purchased",
        settlement: "settlement",
        Completed: "Completed",
    },
    Lockming: {
        Minimum_single: "Minimum single transaction",
        Daily_yield: "Daily yield",
        Lock_up_cycle: "Lock up cycle",
        payout_time: "payout time",
        everyday: "everyday",
        Custody_funds: "Custody funds",
        Return_expiration: "Return upon expiration",
        called_away: "called away",
        Amount_currency: "Amount currency",
        placeholder: "Please enter the content",
        all: "all",
        Estimated_income: "Estimated income",
        Available_assets: "Available assets",
        subscription: "subscription",
        Lock_mining: "Lock in mining",
        Funds_custody: "Funds under custody",
        Estimated_yield_today: "Estimated yield today",
        Accumulated_income: "Accumulated income",
        Orders_custody: "Orders in custody",
        Delegated_Order: "Delegated Order",
        Lock: "Lock",
        day: "day",
        Lock_list: "Lock list",
        Exited: "Exited",
        Redeemed: "Redeemed",
        have_hand: "have in hand",
        redeem: "redeem",
        Amount_currency: "Amount of currency",
        Daily_yield: "Daily yield",
        Lockup_time: "Lockup time",
        Expiration_time: "Expiration time",
        early_redemption: "Liquidated damages for early redemption",
    },
    Market: {
        Optional: "Optional",
        Transaction_pair: "Transaction pair",
        Latest_price: "Latest price",
        place_search: "Search for the currency/stock name/code you care about",
    },
    Setting: {
        label_currency: "Select Currency",
        place_currency: "Please select a currency",
        label_walletTitle: "Wallet name",
        place_walletTitle: "Please enter the wallet name",
        label_walletAddress: "Wallet address",
        place_walletAddress: "Please enter the wallet address",
        Add_bankCard: "Add bank card",
        label_name: "Real name",
        label_depositBank: "Bank of deposit",
        label_bankCard: "Bank card No",
        APP_download: "APP download",
        label_currentPassword: "Current password",
        place_currentPassword: "Please enter the current password",
        label_newPassword: "New Login Password",
        place_newPassword: "Please enter a new login password",
        label_confirmationPassword: "Confirm new password",
        place_confirmationPassword: "Please enter a new password to confirm",
        Real_mark1: "Personal identity authentication",
        Real_mark2: "Permissions available after certification:",
        Real_mark3: "Lv1.Basic certification",
        Real_mark4: "It can be reflected after certification, with a 24-hour limit of 200BTC",
        Real_mark5: "After authentication, transactions in French currency can be conducted, with a single transaction limit of 2000USDT",
        Real_mark6: "Lv2.Advanced Certification",
        Real_mark7: "Increase the reflected limit, with a 24-hour limit of 500BTC",
        Real_mark8: "Increase the transaction limit in French currency, with a single limit of 1000000 USD",
        Real_mark9: "Tips: To protect the security of users' funds, please first complete the binding of email and phone, or your authentication will fail.",
        Real_mark10: "Basic certification",
        Real_mark11: "Certified",
        Real_mark12: "Advanced Certification",
        Real_mark13: "De certification",
        label_realName: "real name",
        message_realName: "Please enter your real name",
        label_cardNumber: "ID number",
        message_cardNumber: "Please enter ID number number",
        upload_front: "Upload the front of ID card",
        upload_back: "Upload the back of ID card",
        upload_people: "Upload the hand-held ID card",
        under_review: "Under review",
        security_setting: "security setting",
        Change_Loginpsd: "Change Login Password",
        Change_transactionpsd: "Modify transaction password",
        email: "email",
        my: "My",
        walletAddress: "Wallet address",
        Default_currency: "Default legal currency",
        language: "language",
        share: "share",
        logout: "logout",
        qrcode: "MY Qrcode",
        personal: "personal information",
        identity: "identity authentication",
        nickname: "nickname",
        nickname_tip: "2-10 characters, supporting Chinese and English, numbers",
        Add_newAccount: "Add a new account",
    },
    Task: {
        daily_tasks: "Daily tasks",
        activity_today: "Activity today",
        task_list: "Task list",
        weekly_tasks: "Weekly tasks",
        activity: "Activity",
        undone: "undone",
        receive: "receive",
        task_rules: "Task rules",
        task_rules1: "1：The activity is accumulated and superimposed within a week, and it is automatically cleared and re-settled every Monday at 0:00 a.m.；",
        task_rules2: "2：Complete all 4 daily tasks every day and get 5 CCA coins after reaching 100 activity level；",
        task_rules3: "：When the activity reaches 350, you can receive 10 CCA coins；",
        task_rules1: "4：When the activity reaches 700, you can receive the N1 mining machine for 1 day of experience；",
        sign_task: "Sign in task",
        Sign_everyDay: "Sign in every day",
        CCA_coin: "CCA Coin",
        signIn: "Sign in",
        day: "Day",
        daily_checkIn: "daily check-in",
        put_away: "Put away",
        Expand: "Expand",
        signIn_now: "Sign in now",
        checkIn_rules: "Check-in rules",
        checkIn_rules1: "1：5 CCA coins will be awarded for consecutive sign-in for 5 days；",
        checkIn_rules2: "2：20 CCA coins will be awarded for consecutive sign-in for 14 days；",
        checkIn_rules3: "3：Sign in for 21 consecutive days to reward N1 mining machine experience time for three days；",
        checkIn_rules4: "4：Keep the check-in record uninterrupted after 24 consecutive days of check-in, and you can get 5 CCA coins for daily check-in；",
    },
    Transaction: {
        delegate_List: "Delegate List",
        risk_rate: "Risk rate",
        total_income: "Total position income",
        oneClick_closing: "One click closing",
        optional: "optional",
        all_closed: "All positions closed",
        flat_multiple: "Only flat and multiple orders",
        flat_empty: "Only flat and empty bill",
        purchase: "purchase",
        sellOut: "sell Out",
        market_price: "market price",
        fixed_price: "fixed price",
        trading_hands: "trading hands",
        multiple: "multiple",
        bond: "bond",
        Service_Charge: "Service Charge",
        balance: "balance",
        long: "long",
        Short: "Short",
        Price: "Price",
        number: "number",
        type: "type",
        time: "time",
        operation: "operation",
        Transaction_pair: "Transaction pair",
        direction: "direction",
        search: "search",
        Latest_price: "Latest price",
        Rise_fall: "Rise fall",
        entrust: "entrust",
        all: "all",
        entrust_time: "entrust time",
        state: "state",
        Completed: "Completed",
        air_completed: "air completed",
        place_amount: "Please enter the content",
        turnover: "turnover",
        contract: "contract",
        option: "option",
        coins: "coins",
        High_today: "High today",
        Low_today: "Low today",
        Entrusted_bill: "Entrusted bill",
        Buy_more: "Buy more",
        Buy_less: "Buy less",
        crm_order: "acknowledgement of order",
        Current_price: "Current price",
        expiration_time: "Select expiration time",
        Option_position: "Option position",
        position: "position",
        history: "history",
        Purchase_price: "Purchase price",
        Order_duration: "Order duration",
        Selling_time: "Selling time",
        Profit_loss: "Profit and loss",
    },
    footer: {
        home: "home",
        market: "Market",
        transaction: "Transaction",
        financial: "Financial",
        fund: "Fund",
        rule: "Rule",
        select_language: "Please select a language"
    },
    common: {
        noData: "No relevant data yet",
        Exchange: "Exchange",
        Cancel: "Cancel",
        Confirm: "Confirm",
        Submit: "Submit",
        more: "more",
        copy: "Replicating Success",
    },
};