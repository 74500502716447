import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

//导入语言包
import En from '../lang/en.js'; // 英文语言包
import Zh from '../lang/zh.js'; // 中文语言包


const messages = {
    en: En, // 英文语言包
    zh: Zh, // 中文语言包

}
window.localStorage.setItem("lang", 'zh')
    // window.sessionStorage.setItem("lang", 'zh')
export default new VueI18n({
    locale: 'zh', // set locale 默认显示英文
    fallbackLocale: 'en', //如果语言包没有，则默认从中文中抽取
    messages: messages // set locale messages
});