import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./plugins/element.js";
// 导入全局样式表
import "./assets/css/global.css";
// 导入iconfont字体库
import "../static/font/iconfont/iconfont.css";
// 导入多语言文件
import i18n from "./tools/i18n.js";
import lang from "./tools/lang.js";
window.lang = lang;
import VueClipBoard from 'vue-clipboard2'
// 全局导入Vant
import Vant from 'vant';
import 'vant/lib/index.css';
import * as filters from './filter'
Vue.use(Vant);

Vue.use(VueClipBoard)

Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})
    // 引入echarts
// import * as echarts from 'echarts'
// Vue.prototype.$echarts = echarts



Vue.config.productionTip = false;

new Vue({
    router,
    i18n,
    render: (h) => h(App),
}).$mount("#app");